import useJwt from '@/auth/jwt/useJwt'

/**
 * Return if user is logged in
 * This is completely up to you and how you want to store the token in your frontend application
 * e.g. If you are using cookies to store the application please update this function
 */
// eslint-disable-next-line arrow-body-style
export const isUserLoggedIn = () => {
  return localStorage.getItem('userData') && localStorage.getItem(useJwt.jwtConfig.storageTokenKeyName)
}

export const getUserData = () => JSON.parse(localStorage.getItem('userData'))

/**
 * This function is used for demo purpose route navigation
 * In real app you won't need this function because your app will navigate to same route for each users regardless of ability
 * Please note role field is just for showing purpose it's not used by anything in frontend
 * We are checking role just for ease
 * NOTE: If you have different pages to navigate based on user ability then this function can be useful. However, you need to update it.
 * @param {String} userRole Role of user
 */
export const getHomeRouteForLoggedInUser = userRole => {
  if (userRole === 'TenantAdmin') return '/'
  if (userRole === 'TenantUser') return '/survey'
  if (userRole === 'client') return { name: 'access-control' }
  return { name: 'auth-login' }
}

/**
 * @param {String} userRole
 */
export const roleToDisplayName = userRole => {
  let displayName = ''
  if (userRole === 'TenantAdmin') {
    displayName = 'Administrator'
  } else if (userRole === 'TenantUser') {
    displayName = 'Employee'
  } else if (userRole === 'SystemAdmin') {
    displayName = 'System Admin'
  } else if (userRole === 'SystemUser') {
    displayName = 'Customer Support'
  }

  return displayName
}

/**
 * TODO refactor this to map to what users can do later.
 * @param {String} userRole
 */
export const getAbilityFromRole = userRole => {
  let ability = [
    {
      action: 'read',
      subject: 'ACL',
    },
    {
      action: 'read',
      subject: 'Auth',
    },
  ]
  if (userRole === 'TenantAdmin' || userRole === 'SystemAdmin' || userRole === 'SystemUser') {
    ability = [
      {
        action: 'manage',
        subject: 'all',
      },
    ]
  }
  if (userRole === 'TenantUser') {
    ability = [
      {
        action: 'read',
        subject: 'Survey',
      },
    ]
  }
  return ability
}

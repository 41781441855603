import Vue from 'vue'
import VueRouter from 'vue-router'

import { canNavigate } from '@/libs/acl/routeProtection'
import { isUserLoggedIn, getUserData, getHomeRouteForLoggedInUser } from '@/auth/utils'

Vue.use(VueRouter)
const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior() {
    return { x: 0, y: 0 }
  },
  routes: [
    {
      path: '/',
      name: 'home',
      component: () => import('@/views/Home.vue'),
      meta: {
        pageTitle: 'Home',
        breadcrumb: [
          {
            text: 'Home',
            active: true,
          },
        ],
      },
    },
    {
      path: '/login/:user/:pass',
      name: 'auth-login-new',
      component: () => import('@/views/auth/Login.vue'),
      meta: {
        layout: 'full',
        resource: 'Auth',
      },
    },
    {
      path: '/login',
      name: 'auth-login',
      component: () => import('@/views/auth/Login.vue'),
      meta: {
        layout: 'full',
        resource: 'Auth',
        redirectIfLoggedIn: true,
      },
    },
    {
      path: '/forgot/password',
      name: 'auth-forgot-password',
      component: () => import('@/views/auth/ForgotPassword.vue'),
      meta: {
        layout: 'full',
        resource: 'Auth',
        redirectIfLoggedIn: true,
      },
    },
    {
      path: '/forgot/password/success',
      name: 'auth-forgot-password-success',
      component: () => import('@/views/auth/ForgotPasswordSuccess.vue'),
      meta: {
        layout: 'full',
        resource: 'Auth',
        redirectIfLoggedIn: true,
      },
    },
    {
      path: '/register',
      name: 'auth-register',
      component: () => import('@/views/auth/Register.vue'),
      meta: {
        layout: 'full',
        resource: 'Auth',
        redirectIfLoggedIn: true,
      },
    },
    {
      path: '/register/success',
      name: 'auth-register-success',
      component: () => import('@/views/auth/RegisterSuccess.vue'),
      meta: {
        layout: 'full',
        resource: 'Auth',
        redirectIfLoggedIn: true,
      },
    },
    {
      path: '/user/create/password/:user/:pass',
      name: 'auth-create-password',
      component: () => import('@/views/auth/CreatePassword.vue'),
      meta: {
        layout: 'full',
        resource: 'Auth',
        redirectIfLoggedIn: true,
      },
    },
    {
      path: '/user/reset/password/:user/:token',
      name: 'auth-reset-password',
      component: () => import('@/views/auth/ResetPassword.vue'),
      meta: {
        layout: 'full',
        resource: 'Auth',
        redirectIfLoggedIn: true,
      },
    },
    {
      path: '/user/:id/update/password',
      name: 'auth-update-password',
      component: () => import('@/views/auth/UpdatePassword.vue'),
      meta: {
        layout: 'full',
        resource: 'Auth',
        redirectIfLoggedIn: true,
      },
    },
    {
      path: '/not-authorized',
      name: 'not-authorized',
      component: () => import('@/views/error/NotAuthorized.vue'),
      meta: {
        layout: 'full',
        resource: 'Auth',
      },
    },
    {
      path: '/error-404',
      name: 'error-404',
      component: () => import('@/views/error/Error404.vue'),
      meta: {
        layout: 'full',
        resource: 'Auth',
        action: 'read',
      },
    },
    {
      path: '/insights',
      name: 'insights',
      component: () => import('@/views/insights/Insights.vue'),
      meta: {
        pageTitle: 'Insights',
        breadcrumb: [
          {
            text: 'Insights',
            active: true,
          },
        ],
      },
    },
    {
      path: '/actions',
      name: 'actions',
      component: () => import('@/views/actions/Actions.vue'),
      meta: {
        pageTitle: 'Actions',
        breadcrumb: [
          {
            text: 'Actions',
            active: true,
          },
        ],
      },
    },
    {
      path: '/user/manage',
      name: 'manage-users',
      component: () => import('@/views/user/users-list/UsersList.vue'),
      meta: {
        pageTitle: 'Manage Users',
        breadcrumb: [
          {
            text: 'Manage Users',
            active: true,
          },
        ],
      },
    },
    {
      path: '/user/edit/:id',
      name: 'edit-user',
      component: () => import('@/views/user/users-edit/UsersEdit.vue'),
      meta: {
        pageTitle: 'Edit User',
        breadcrumb: [
          {
            text: 'Edit User',
            active: true,
          },
        ],
      },
    },
    {
      path: '/survey/completed',
      name: 'survey-completed',
      component: () => import('@/views/survey/Completed.vue'),
      meta: {
        layout: 'full',
        resource: 'Survey',
        action: 'read',
      },
    },
    {
      path: '/survey/closed',
      name: 'survey-closed',
      component: () => import('@/views/survey/Closed.vue'),
      meta: {
        layout: 'full',
        resource: 'Survey',
        action: 'read',
      },
    },
    {
      path: '/survey/thankyou',
      name: 'survey-thankyou',
      component: () => import('@/views/survey/ThankYou.vue'),
      meta: {
        layout: 'full',
        resource: 'Auth',
        action: 'read',
      },
    },
    {
      path: '/survey',
      name: 'survey',
      component: () => import('@/views/survey/Survey.vue'),
      meta: {
        resource: 'Survey',
        action: 'read',
        pageTitle: 'Take Survey',
        breadcrumb: [
          {
            text: 'Take Survey',
            active: true,
          },
        ],
      },
    },
    {
      path: '*',
      redirect: 'error-404',
    },
  ],
})

router.beforeEach((to, _, next) => {
  const isLoggedIn = isUserLoggedIn()

  if (!canNavigate(to)) {
    // Redirect to login if not logged in
    if (!isLoggedIn) return next({ name: 'auth-login' })

    // If logged in => not authorized
    return next({ name: 'not-authorized' })
  }

  // Redirect if logged in
  if (to.meta.redirectIfLoggedIn && isLoggedIn) {
    const userData = getUserData()
    next(getHomeRouteForLoggedInUser(userData ? userData.role : null))
  }

  return next()
})

export default router
